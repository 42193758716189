// import { FirmRole } from './../../models/view-model/firm-role-view';
import { FirmRoleService } from './../../services/firm-role.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as firmRoleActions from '../actions/firm-roles.action';
import { mergeMap, map, catchError, tap, concatMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { UserManagementService } from '../../services/user-management.service';
import { FirmRole } from '../../models/view-model/role-list';
import { UserFirmRole } from '../../models/view-model/user-firm-role';
import { EditRoleModel } from '../../models/edit-model/edit-role';
import { Router } from '@angular/router';

@Injectable()
export class FirmRoleEffects {
    constructor(private actions$: Actions,
         private firmRoleService: FirmRoleService,
          private service: UserManagementService,
          private router: Router) {
    }
    // @Effect()
    // loadFirmRoles$ = this.actions$.pipe(
    //     ofType(firmRoleActions.FirmRoleActionTypes.LoadRoles),
    //     mergeMap((action:firmRoleActions.LoadRoles)=>this.firmRoleService.getFirmRoles()
    //     .pipe(
    //         map((firmRoles:FirmRole[])=>new firmRoleActions.LoadRolesSuccess(firmRoles)),
    //         catchError(err=>of(new firmRoleActions.LoadRolesFail(err)))
    //     )
    // )
    // )
    @Effect()
    loadFirmRoles$ = this.actions$.pipe(
        ofType(firmRoleActions.FirmRoleActionTypes.LoadRoles),
        mergeMap((action: firmRoleActions.LoadRoles) => this.service.getRolesList(action.payload)
        .pipe(
            map((firmRoles: FirmRole[]) => new firmRoleActions.LoadRolesSuccess(firmRoles)),
            catchError(err => of(new firmRoleActions.LoadRolesFail(err)))
        )
      )
    );

    @Effect()
    loadRole$ = this.actions$.pipe(
        ofType(firmRoleActions.FirmRoleActionTypes.setCurrentRole),
        mergeMap((action: firmRoleActions.SetCurrentRole) => this.service.getRole(action.payload.firmId, action.payload.roleId)
        .pipe(
            map((firmRole: FirmRole) => new firmRoleActions.SetCurrentRoleSuccess(firmRole)),
            catchError(err => of(new firmRoleActions.SetCurrentRoleFail(err)))
        ))
    );

    // @Effect()
    // updateRole$ = this.actions$.pipe(
    //     ofType(firmRoleActions.FirmRoleActionTypes.UpdateRoles),
    //     mergeMap((action: firmRoleActions.UpdateRoles) => this.service.updateRolePermission(action.payload)
    //     .pipe(
    //         map((firmRole: EditRoleModel) => new firmRoleActions.UpdateRolesSuccess(firmRole)),
    //         catchError(err => of(new firmRoleActions.UpdateRolesFail(err)))
    //     ))
    // );

    @Effect()
    updateRole$ = this.actions$.pipe(
        ofType(firmRoleActions.FirmRoleActionTypes.UpdateRoles),
        concatMap((action: firmRoleActions.UpdateRoles) => this.service.updateRolePermission(action.payload)
        .pipe(
            map((firmRole: EditRoleModel) => new firmRoleActions.UpdateRolesSuccess(firmRole)),
            catchError(err => of(new firmRoleActions.UpdateRolesFail(err)))
        ))
    );
}
