import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route
} from "@angular/router";
import {PermissionService} from 'src/app/shared/services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class ReportGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkPermission(url);
  }

  checkPermission(url: string): boolean {
    if (this.permissionService.checkPermission("Report", "canView")) {
      return true;
    }

    this.router.navigate(["/firm"]);
  }
}
