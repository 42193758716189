import { Injectable } from '@angular/core';
import { AppConfigService } from '../shared/helpers/app-config.service';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { ClientFeedback } from '../client-management/models/feedback';
import { IFirmDetails, FirmUser, IFirmUserDetails, IFirmDetailsAPIResponse, IApplicationFirmUserAPIResponse } from '../models/application-support-model/application-support';

@Injectable({
  providedIn: 'root'
})
export class InHouseSubscriptionsService {
  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  // This section reloads the data for refetching
  private subject = new BehaviorSubject<boolean>(true);
  changeDecision(reload: boolean) {
    this.subject.next(reload);
  }
  getDecision(): Observable<any> {
    return this.subject.asObservable();
  }
  unSubscribeReload(): void {
    this.subject.unsubscribe();
  }


  getFirmsSubscriptions(page?: number, pageSize?: number, search?: string, subscriptionType?: number, startDate?: string, endDate?: string): Observable<HttpResponse<IFirmDetailsAPIResponse>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}application-support/firms/subscriptions`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("page", page.toString());
    if (pageSize) {
      queryParams = queryParams.append("pageSize", pageSize.toString());
    }
    if (search) {
      queryParams = queryParams.append("search", search);
    }
    if (subscriptionType) {
      queryParams = queryParams.append("subscriptionType", subscriptionType.toString());
    }
    if (startDate) {
      queryParams = queryParams.append("StartDate", startDate);
    }
    if (endDate) {
      queryParams = queryParams.append("EndDate", endDate);
    }
    return this.httpClient.get<IFirmDetailsAPIResponse>(endpoint, { observe: "response", params: queryParams });
  }

  getFirmsUsers(page?: number, pageSize?: number, search?: string, isActive?: string): Observable<HttpResponse<IApplicationFirmUserAPIResponse>> {
    var endpoint = `${this.config.getConfig().serverEndpoint}application-support/firms`;

    let queryParams = new HttpParams();
    queryParams = queryParams.append("page", page.toString());
    queryParams = queryParams.append("pageSize", pageSize.toString());
    if (search) {
      queryParams = queryParams.append("search", search);
    }

    if (isActive) {
      queryParams = queryParams.append("isActive", isActive);
    }

    console.log(endpoint)

    return this.httpClient.get<IApplicationFirmUserAPIResponse>(endpoint, { observe: "response", params: queryParams });
  }

  getFirmDetails(firmId: number, page = 0, pageSize = 10): Observable<HttpResponse<IFirmDetails>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}application-support/firm/${firmId}/overview`;
    return this.httpClient.get<IFirmDetails>(endpoint, { observe: "response" });
  }

  resendConfirmationMail(email: string): Observable<HttpResponse<any>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}firm/ResendComfirmation`;
    return this.httpClient.post<[]>(endpoint, { email }, { observe: "response" });
  }

  getFirmUsers(firmId: number, page = 0, pageSize = 10): Observable<HttpResponse<IFirmUserDetails[]>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}application-support/${firmId}/user`;
    return this.httpClient.get<[]>(endpoint, { observe: "response" });
  }

  updateFirmUser(userDetail: FirmUser): Observable<HttpResponse<any>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}application-support/user`;
    return this.httpClient.put<any>(endpoint, userDetail, { observe: "response" });
  }

  getFirmModulesMetrics(firmId: number, year: number, featuresId?: number[]): Observable<HttpResponse<any>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}application-support/feature-metrics/graph-values`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append("Year", year.toString());
    queryParams = queryParams.append("FirmId", firmId.toString());
    if (featuresId) {
      queryParams = queryParams.append("FeatureIds", featuresId.toString());
    }

    return this.httpClient.get<any>(endpoint, { observe: "response", params: queryParams });
  }

  suspendFirm(payload: any): Observable<HttpResponse<any>> {
    const endpoint = `${this.config.getConfig().serverEndpoint}firm/suspend-switch`;
    return this.httpClient.post<[]>(endpoint, payload, { observe: "response" });
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.', error.error.message);
  }
}
