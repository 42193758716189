export class DashBoardOverView {
  firmClientCount: number;
  firmLeadCount: number;
  firmMessageCount: number;
  firmUnCompletedApplicationCount: number;
  firmUnCompletedMatterCount: number;
  firmUnCompletedEndorsementCount: number;
  firmCompletedMatterCount: number;
  firmUserUnCompletedTaskCount: number;
  userUnCompletedTaskCount: number;
  userCompletedTaskCount: number;

  /**
   *
   */
  constructor() {
    this.firmClientCount = 0;
    this.firmLeadCount = 0;
    this.firmMessageCount = 0;
    this.firmUnCompletedApplicationCount = 0;
    this.firmUnCompletedEndorsementCount = 0;
    this.firmUnCompletedMatterCount = 0;
    this.firmUserUnCompletedTaskCount = 0;
    this.userCompletedTaskCount = 0;
    this.userUnCompletedTaskCount = 0;
    this.firmCompletedMatterCount = 0;
  }

}
export class UserDashboardOverview {
  messageCount: number;
  taskCount: number;
  todaysEventCount: number;
  tomorrowsEventCount: number;
  userOngoingCase: number;
  userTotalTasks: number;
  documentCount: number;
  loggedTime: number;
  upcomingEvents?: number
}

export const dashBoardOverViewSample = {
  firmClientCount: 12,
  firmLeadCount: 2,
  firmMessageCount: 20,
  firmUnCompletedApplicationCount: 4,
  firmUnCompletedMatterCount: 5,
  firmUnCompletedEndorsementCount: 11,
  firmCompletedMatterCount: 1,
  firmUserUnCompletedTaskCount: 3,
  userUnCompletedTaskCount: 12,
  userCompletedTaskCount: 2
};
export const userDashboardOverviewSample = {
  messageCount: 9,
  taskCount: 4,
  eventCount: 3,
  documentCount: 21,
  loggedTime: 1
};
