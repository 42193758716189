import { Injectable } from '@angular/core';
import { OkmExtractedFileModel, OkmUploadModel } from '../models/okm-upload.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from 'src/app/shared/helpers/app-config.service';
import { Observable } from 'rxjs';
import { ChunkFileModel, OkmGptModel, OkmResultModel } from 'src/app/documents/models/okmresults.model';

@Injectable({
  providedIn: 'root'
})
export class OkmService {

  documentCreationText: string = "";

  uploadedBinary: string = "";

  documentMode: string;

  // extractedFile: OkmExtractedFileModel;
  okmReviewResults: OkmResultModel[];
  selectedResult: any;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) { }

  public uploadFileToExtract(fileToUpload: OkmUploadModel): Observable<any> {
    // console.log(fileToUpload);
    const url = this.config.getConfig().serverEndpoint + `okm/search/upload`;
    return this.httpClient.post<any>(url, fileToUpload);
  }

  public extractChunkFile(chunk: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("chunkFileUniqueName", chunk.toString());

    const url = this.config.getConfig().serverEndpoint + `okm/extract-chunk-pdf`;
    return this.httpClient.get<any>(url, { params: queryParams });
  }

  public reviewWithOkm(fileToUpload: any): Observable<OkmResultModel[]> {
    // console.log(fileToUpload);
    const url = this.config.getConfig().serverEndpoint + `okm/review`;
    return this.httpClient.post<any>(url, fileToUpload);
  }

  public chatWithOkm(query: any): Observable<OkmGptModel> {
    // console.log(fileToUpload);
    const url = this.config.getConfig().serverEndpoint + `okm/gpt`;
    return this.httpClient.post<any>(url, query);
  }

  public downloadLargeChunk(fileUniqueName: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("fileUniqueName", fileUniqueName.toString());

    const url = this.config.getConfig().serverEndpoint + `file/details`;
    return this.httpClient.get<any>(url, { params: queryParams });
  }

}
