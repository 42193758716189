import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { Observable } from "rxjs";
import { RenameDocumentModel } from "../models/view-model/document-rename";

@Injectable({
  providedIn: "root",
})
export class RenameDocumentService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {}

  // RENAME FILE FRIM DOCUMENTS
  public fileUpdate(
    renameDocuments: RenameDocumentModel
  ): Observable<RenameDocumentModel> {
    const api = this.config.getConfig().serverEndpoint + `file/Update`;
    //console.log(api);
    return this.httpClient.put<RenameDocumentModel>(api, renameDocuments);
  }

  // RENAME FILE FRIM DOCUMENTS
  public renameDocuments(
    renameDocuments: RenameDocumentModel
  ): Observable<RenameDocumentModel> {
    const api = this.config.getConfig().serverEndpoint + `file/Rename`;
    //console.log(api);
    return this.httpClient.put<RenameDocumentModel>(api, renameDocuments);
  }

  // RENAME FILE FRIM DIRECTORY

  public renameDirectory(
    renameDocuments: RenameDocumentModel
  ): Observable<RenameDocumentModel> {
    const api =
      this.config.getConfig().serverEndpoint + `file/Directory/Rename`;
    //console.log(api);
    return this.httpClient.put<RenameDocumentModel>(api, renameDocuments);
  }
}
