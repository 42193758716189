import { FileUploadModel } from "../generic-model.ts/file-upload";
import { InvoiceFile } from "../parse-model/add-invoice-item";
import { fileViewModel } from "../view-model/fileViewModel";

export class EditFirmDetailsModel {
  id: number;
  name: string;
  email?: string;
  website?: string;
  phoneNumber?: string;
  address?: string;
  countryId?: number;
  stateId?: number;
  state?: string;
  country?: string;
  creator?: number;
  fileModel: FileUploadModel;
  contactEmail?: string;
  invoiceFiles: InvoiceFile;
  logo: fileViewModel;
}
