export class ApplicationParseModel {
  id?: number;
  matterId: number;
  prayers: string;
  title: string;
  status: number;
  name: string;
  dateFiled: string;
  applicationTypeId: number;
  dueDate: string;
  files?: FileUpload[];
  reminders?: ApplicationReminder[];
}
export class FileUpload {
  // id: number;
  fileName: string;
  fileBinary: string;
  fileExtension: string;
}

export interface ApplicationReminder {
  applicationId?:number;
  reminderTime: number;
  message?: string;
 
}
