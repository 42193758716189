import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
  })
export class EpochDateFormat {
    public convertDateToEpochForInput(value: any): string {
        if (value != '' && value != null) {
          if (value.includes('-')) {
            const epoch = Math.floor(new Date(value).getTime());
            const epochString = epoch.toString();
            return epochString;
          } else {
            return value;
          }
        }
        return '';
      }
}
