export class FirmUserForGroup {
    firmId: number;
    firmUserId: number;
}


export class AddUserToGroup {
    userId: number;
    firmGroupId: number;
}
