import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Endpoints } from '../models/shared-model/endpoint';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { dashBoardOverViewSample } from '../models/view-model/user-dashboard';
import { CalendarViewModel } from '../models/view-model/calendar-event';
import { userDashboardOverviewSample } from '../models/view-model/user-dashboard';
import { UserTask } from '../models/view-model/user-tasks';
import { AppConfigService } from '../shared/helpers/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  Endpoints = new Endpoints(this.config);

  constructor(private httpClient: HttpClient,
    private config: AppConfigService) {
  }


  public loadAdminDashboardOverview(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/Overview`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
    // return of (userDashboardOverviewSample);
  }

  public loadUserDashboardOverview(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/Useroverview`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
    // return of (userDashboardOverviewSample);
  }

  public loadDashboardOverview(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/Useroverview`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
  }
  public loadDashboardUsertasks(): Observable<UserTask[]> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/Usertasks`;
    return this.httpClient.get<UserTask[]>(url).pipe(
      catchError(this.handleError)
    );
    // return of (userTaskListSample);
  }
  public loadDashboardFirmlead(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/firmlead`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
    // return of (dashboardOverviewSample);
  }
  public loadDashboardFirmOnGoingMatter(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/firmongoingmatter`;
    return this.httpClient.get(url).pipe(
      catchError(this.handleError)
    );
    // return of (dashboardOverviewSample);
  }
  loadDashboardUserOngoingMatter(): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + `Dashboard/userongoingmatter`;
    return this.httpClient.get(url).pipe(catchError(this.handleError));
  }


  // public loadUserTaskCalendar(month: number = 0, year: number): Observable<any> {
  //   const url =  this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/userTaskCalender`;
  //   return this.httpClient.get(url).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  // public loadFirmProceedingCalendar(month: number = 0, year: number):  Observable<any> {
  //   const url =  this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/firmproceedingcalender`;
  //   return this.httpClient.get(url).pipe(
  //     catchError(this.handleError)
  //   );
  // }
  // public loadFirmApplicationCalendar(month: number = 0, year: number):  Observable<any> {
  //   const url =  this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/firmapplicationcalender`;
  //   return this.httpClient.get(url).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  // public loadUserReminderCalendar(month: number = 0, year: number):  Observable<any> {
  //   const url =  this.config.getConfig().serverEndpoint + `Dashboard/${month}/${year}/userremindercalender`;
  //   return this.httpClient.get(url).pipe(
  //     catchError(this.handleError)
  //   );
  // }





  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.', error.error.message);
  }

}
